/* Overlay semi-transparent */
.cookie-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999; /* Priorité maximale */
}


/* Styles spécifiques aux boutons dans .cookie-modal */
.cookie-modal .view-details {
  background: none;
  border: none;
  color: #007bff; /* Couleur bleue pour les liens */
  cursor: pointer;
  font-size: 12px;
}

.cookie-modal .view-details:hover {
  background: none; /* Pas de fond au survol */
  color: #0056b3; /* Couleur bleue plus foncée au survol */
}

/* Modal cookie */
.cookie-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #222;
  color: #fff;
  padding: 20px;
  width: 90%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 10000;
  overflow-y: auto; /* Scroll activé pour mobiles */
  max-height: 80vh; /* Limite pour éviter le dépassement */
}

.cookie-title {
  font-weight: bold; /* Texte en gras */
  text-align: center; /* Centrer le texte horizontalement */
  font-size: 1.8em; /* Ajuster la taille de la police */
  margin-bottom: 20px; /* Espacement sous le titre */
  line-height: 1.2; /* Réduire l'espacement entre les lignes */
  color: #fff; /* Couleur blanche pour contraster avec le fond */
  word-wrap: break-word; /* Permettre de casser les mots */
}

.cookie-description {
  text-align: center; /* Centrer le texte horizontalement */
  font-size: 0.8em; /* Réduire davantage la taille de la police */
  line-height: 1.5; /* Espacement réduit entre les lignes */
  margin: 10px 0; /* Espacement vertical réduit */
  color: #fff; /* Couleur blanche pour contraster avec le fond */
  max-width: 600px; /* Limiter la largeur pour un meilleur alignement */
  margin-left: auto; /* Centrer horizontalement */
  margin-right: auto; /* Centrer horizontalement */
}

/* Titres et contenu */
.cookie-modal h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.cookie-modal p {
  margin-bottom: 20px;
  font-size: 0.9em;   /* Description */
  line-height: 1.5;
}

/* Description avec les points au debut */

.cookie-list {
  font-size: 0.9em; /* Réduit la taille de la police */
  line-height: 1.4; /* Ajuste l’espacement des lignes */
  margin-left: 20px; /* Ajoute un retrait à gauche pour la liste */
}

.cookie-list li {
  list-style-type: disc; /* Ajoute un point devant chaque élément */
  margin-bottom: 10px; /* Espace entre les lignes */
  color: #fff; /* Assure que le texte est bien visible */
}

.cookie-list strong {
  font-weight: bold; /* Maintient le texte en gras pour les titres Cookies essentiels Cookies marketing etc */
}

.cookie-subtitle {
  font-size: 1.2em; /* Taille du texte */
  font-weight: bold; /* Texte en gras */
  text-align: center; /* Centre le texte horizontalement */
  color: #fff; /* Couleur blanche */
  margin-bottom: 15px; /* Espacement sous le titre */
}


/* Catégories avec boutons "En savoir plus" */

/* Section contenant les lignes */
.cookie-info-section {
  display: flex;
  flex-direction: column; /* Empile les lignes verticalement */
  gap: 15px; /* Espacement vertical entre les lignes */
  align-items: center; /* Centre les lignes horizontalement */
  margin: 15px 0; /* Espacement global autour de la section */
}

/* Chaque ligne d'icônes */
.cookie-row {
  display: flex;
  justify-content: center; /* Centre les icônes dans chaque ligne */
  gap: 20px; /* Espacement horizontal entre les icônes */
  width: 100%; /* S'assurer que la ligne occupe toute la largeur disponible */
}

/* Style des icônes */
.cookie-info {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centre les icônes et le texte */
  text-align: center; /* Aligne le texte au centre */
}

/* Icônes */
.cookie-info .icon {
  font-size: 1.8em; /* Taille réduite des icônes pour un look plus subtil */
  margin-bottom: 5px; /* Espace entre l'icône et le titre */
  color: #fff; /* Couleur des icônes */
}

/* Titres */
.cookie-info h4 {
  font-size: 0.9em; /* Taille plus petite pour un style subtil */
  margin-bottom: 5px; /* Espace entre le titre et le bouton */
  color: #fff; /* Couleur des titres */
}

/* Boutons "En savoir plus" */
.info-button {
  background-color: #3d3a3a; /* Couleur du bouton */
  color: #fff; /* Couleur du texte */
  border: none;
  border-radius: 5px; /* Coins arrondis */
  padding: 8px 12px; /* Taille du bouton */
  text-decoration: none; /* Supprime le soulignement */
  font-size: 0.8em; /* Taille du texte dans le bouton */
  text-align: center; /* Centre le texte */
  cursor: pointer;
  margin-top: 5px; /* Espace entre le bouton et les autres éléments */
}

.info-button:hover {
  background-color: #0056b3; /* Couleur au survol */
}


 /* Overlay complet */
.cookie-overlay-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Fond semi-transparent */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10001; /* Par-dessus la modal */
  overflow-y: auto; /* Permet le défilement vertical */
}

 /* Conteneur d'overlay */
 .cookie-overlay-container {
  background-color: #222;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px; /* Limite la largeur */
  max-height: 80vh; /* Limite la hauteur pour permettre le défilement */
  overflow-y: auto; /* Active le défilement si le contenu dépasse */
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.cookie-overlay-container h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
  text-align: center;
}

.cookie-overlay-container p {
  margin-bottom: 10px; /* Réduction de l’espacement entre paragraphes */
}

.cookie-overlay-container a {
  color: #007bff;
  text-decoration: none;
}

.cookie-overlay-container a:hover {
  text-decoration: underline;
}

.close-overlay {
  display: block;
  margin: 20px auto 0; /* Positionnement du bouton */
  background: #e63946; /* Couleur rouge */
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 0.9em;
  cursor: pointer;
  text-align: center;
}

.close-overlay:hover {
  background: #d62828; /* Rouge plus sombre au survol */
}



.cookie-optional-section {
  text-align: left; /* Aligne tout à gauche */
  margin: 20px 0; /* Ajoute un peu d'espace autour */
}

.cookie-optional-title {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px; /* Ajoute un espace en dessous du titre */
}

.cookie-info .cookie-info-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: left !important; /* Force l'alignement à gauche */
}

.cookie-info-description {
  font-size: 14px;
  line-height: 1.5;
  text-align: left; /* Aligne le texte à gauche */
  margin: 0; /* Supprime toute marge par défaut */
}


.toggle-switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #007bff; /* Bleu pour activé */
}

input:checked + .slider:before {
  transform: translateX(14px);
}


/* ----------------------------- */
/* CSS pour les sous-catégories */
/* ----------------------------- */

.category-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10002;
}

.category-container {
  background-color: #222;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.toggle-list {
  margin: 20px 0;
}

.toggle-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}



















.cookie-modal ul {
  list-style: none;
  padding: 0;
}

.cookie-modal li {
  margin-bottom: 10px;
}

/* Catégories */
.cookie-categories h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.cookie-categories ul {
  list-style: none;
  padding: 0;
}

.cookie-categories li {
  margin-bottom: 15px;
}

.cookie-categories button {
  background-color: #0056b3;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.cookie-categories button:hover {
  background-color: #00408a;
}

/* Footer buttons */
.cookie-footer {
  display: flex;
  flex-wrap: wrap; /* Pour mobiles */
  justify-content: space-between;
  margin-top: 20px;
}

.cookie-footer button {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-bottom: 10px; /* Espacement pour mobiles */
}

/* Style global des boutons */
button.accept-all {
  padding: 10px 25px;
  border-radius: 25px; /* Coins arrondis */
  font-size: 16px;
  font-weight: 500; /* Texte légèrement en gras */
  cursor: pointer;
  border: 2px solid #007bff; /* Bordure bleu clair */
  color: #007bff; /* Texte bleu clair */
  background-color: transparent; /* Fond transparent */
  transition: background-color 0.3s, color 0.3s; /* Transition douce */
}

button.accept-all:hover {
  background-color: #0056b3; /* Fond bleu foncé au survol */
  color: #ffffff; /* Texte blanc au survol */
}

button.reject-all {
  padding: 10px 25px;
  border-radius: 25px; /* Coins arrondis */
  font-size: 16px;
  font-weight: 500; /* Texte légèrement en gras */
  cursor: pointer;
  border: 2px solid #6c757d; /* Bordure grise */
  color: #6c757d; /* Texte gris foncé */
  background-color: transparent; /* Fond transparent */
  transition: background-color 0.3s, color 0.3s; /* Transition douce */
}

button.reject-all:hover {
  background-color: #343a40; /* Fond gris très foncé au survol */
  color: #ffffff; /* Texte blanc au survol */
}

button.customize-button {
  padding: 10px 25px;
  border-radius: 25px; /* Coins arrondis */
  font-size: 16px;
  font-weight: 500; /* Texte légèrement en gras */
  cursor: pointer;
  border: 2px solid #007bff; /* Bordure bleu clair */
  color: #007bff; /* Texte bleu clair */
  background-color: transparent; /* Fond transparent */
  transition: background-color 0.3s, color 0.3s; /* Transition douce */
}

button.customize-button:hover {
  background-color: #0056b3; /* Fond bleu foncé au survol */
  color: #ffffff; /* Texte blanc au survol */
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .cookie-modal {
    width: 95%;
    max-height: 90vh; /* Limite de hauteur pour mobiles */
    padding: 15px;
  }

  .cookie-footer {
    flex-direction: column;
  }

  .cookie-footer button {
    width: 100%; /* Pleine largeur sur mobile */
  }
}











.customization-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6); /* Couche sombre pour l'arrière-plan */
  z-index: 999;
}

.customization-container {
  background: #333;
  color: #fff;
  width: 90%;
  max-width: 600px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  max-height: 80%; /* Pour éviter qu'elle ne dépasse l'écran */
  overflow-y: auto; /* Ajoute un défilement si le contenu est trop grand */
}

.customization-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.customization-description {
  font-size: 14px;
  margin-bottom: 20px;
}

.customization-categories .category-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.cookie-info-title {
  display: flex;
  color: #ffffff;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.view-details {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 12px;
}

/* Bouton "Enregistrer" */
button.save-button {
  padding: 10px 25px; /* Espacement interne */
  border-radius: 25px; /* Coins arrondis */
  font-size: 16px; /* Taille du texte */
  font-weight: 500; /* Texte légèrement en gras */
  cursor: pointer; /* Curseur pointeur */
  border: 2px solid #007bff; /* Bordure bleu clair */
  color: #007bff; /* Texte bleu clair */
  background-color: transparent; /* Fond transparent */
  transition: background-color 0.3s, color 0.3s; /* Transition douce */
}

button.save-button:hover {
  background-color: #0056b3; /* Fond bleu foncé au survol */
  color: #ffffff; /* Texte blanc au survol */
}

/* Bouton "Annuler" */
button.cancel-button {
  padding: 10px 25px; /* Espacement interne */
  border-radius: 25px; /* Coins arrondis */
  font-size: 16px; /* Taille du texte */
  font-weight: 500; /* Texte légèrement en gras */
  cursor: pointer; /* Curseur pointeur */
  border: 2px solid #6c757d; /* Bordure grise */
  color: #6c757d; /* Texte gris foncé */
  background-color: transparent; /* Fond transparent */
  transition: background-color 0.3s, color 0.3s; /* Transition douce */
}

button.cancel-button:hover {
  background-color: #343a40; /* Fond gris très foncé au survol */
  color: #ffffff; /* Texte blanc au survol */
}