/* src/styles/InventoryPage.css */

/* Container principal */
.inventory-container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
}

/* En-tête */
.inventory-header {
    text-align: center;
    margin-bottom: 20px;
}

/* Conteneur des items */
.inventory-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center; /* Centre les éléments dans le conteneur */
}

/* Item individuel */
.inventory-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    width: calc(25% - 20px); /* Réduit la largeur pour afficher plus d'éléments par ligne */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    max-width: 150px; /* Limite la largeur maximale des éléments */
    position: relative; /* Permet d'utiliser position:absolute pour le temps restant */
    background-color: #f9f9f9; /* Fond légèrement gris */
    transition: background-color 0.3s ease-in-out; /* Animation lors du survol */
}

/* Survol */
.inventory-item:hover {
    background-color: #f0f0f0; /* Change le fond au survol */
}


.inventory-video {
    width: 100%;
    height: auto;
    border-radius: 4px;
    object-fit: cover;
    margin-bottom: 10px;
  }


/* Images */
.inventory-item img {
    width: 100px; /* Réduit la largeur des images */
    height: auto; /* Maintient le ratio de l'image */
    border-radius: 4px;
    margin-bottom: 10px; /* Ajoute un espace entre l'image et le texte */
}

/* Texte des items */
.inventory-item p {
    margin: 5px 0; /* Uniformise les marges entre les paragraphes */
    font-size: 14px; /* Réduit légèrement la taille du texte */
    color: #333;
}

/* Style pour le temps restant */
.inventory-item .time-remaining {
    font-weight: bold;
    color: #ff4500; /* Couleur orange pour attirer l'attention */
    font-size: 14px;
    margin-top: 10px;
}

/* Barre de progression du temps restant */
.inventory-item .progress-bar {
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px;
    height: 4px;
    background-color: #ddd;
    border-radius: 2px;
    overflow: hidden;
}

.inventory-item .progress-bar div {
    height: 100%;
    background-color: #ff4500; /* Barre de progression orange */
    transition: width 0.5s ease-in-out; /* Animation fluide pour la progression */
}


/* Media Queries pour la responsivité */

/* Écrans jusqu'à 1024px */
@media (max-width: 1024px) {
    .inventory-item {
        width: calc(33.33% - 20px); /* 3 items par ligne */
        max-width: 180px;
    }

    .inventory-item img,
    .inventory-item .inventory-video {
        width: 90px; /* Réduit la largeur des vidéos */
        height: auto;
    }

    .inventory-item p {
        font-size: 13px;
    }
}

/* Petits écrans jusqu'à 768px */
@media (max-width: 768px) {
    .inventory-item {
        width: calc(50% - 20px); /* 2 items par ligne */
        max-width: 200px;
    }

    .inventory-item img,
    .inventory-item .inventory-video {
        width: 80px; /* Réduit encore la largeur des vidéos */
        height: auto;
    }

    .inventory-item p {
        font-size: 12px;
    }
}

/* Écrans très petits jusqu'à 480px */
@media (max-width: 480px) {
    .inventory-item {
        width: 100%; /* 1 item par ligne */
        max-width: 300px;
        margin-bottom: 20px; /* Ajoute un espace entre les items */
    }

    .inventory-item img,
    .inventory-item .inventory-video {
        width: 100%; /* La vidéo occupe toute la largeur de l'item */
        max-width: 250px;
        height: auto;
    }

    .inventory-item p {
        font-size: 12px;
    }

    /* Réduit la hauteur et ajuste la position de la barre de progression */
    .inventory-item .progress-bar {
        bottom: 5px; /* Ajuste la position vers le bas */
        height: 3px; /* Réduit l'épaisseur de la barre */
    }
}

/* Ajustement pour les écrans entre 400px et 480px */
@media (max-width: 400px) {
    .inventory-item {
        padding: 5px;
        width: 100%; /* Un item prend toute la largeur */
        max-width: 280px;
    }

    .inventory-item img,
    .inventory-item .inventory-video {
        width: 100%; /* La vidéo prend toute la largeur */
        max-width: 200px;
        height: auto;
    }

    .inventory-item p {
        font-size: 11px;
    }

     /* Réduction de la barre de progression */
     .inventory-item .progress-bar {
        bottom: 5px;
        height: 2px; /* Barre encore plus fine pour les très petits écrans */
    }
}
