/* Mode par défaut : pleine page sur mobile */
.modal-content {
    width: 100vw; /* Largeur = 100% de l'écran */
    height: 100vh; /* Hauteur = 100% de l'écran */
    object-fit: cover; /* L’image remplit l’espace en coupant si nécessaire */
    display: block;
    margin: auto;
  }

  /* 🎥 Correction pour les vidéos */
.modal-content video {
    width: 100%; /* ✅ La vidéo prend toute la largeur */
    height: auto; /* ✅ Ajuste la hauteur en conservant le ratio */
    object-fit: contain; /* ✅ Garde les proportions sans couper */
}


/* Cacher les scrollbars sur les navigateurs modernes */
.stories-container {
    display: flex;
    overflow-x: auto;
    padding: 10px;
    max-width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  /* Pour les grands écrans */
  @media (min-width: 1024px) {
    
    .modal-content {
        width: 80vw;  /* Réduire la largeur sur PC */
        height: 80vh; /* Réduire la hauteur */
        object-fit: contain; /* Garde les proportions sans couper */
        position: relative; /* Permet de recentrer */
        display: block;
        margin: auto;
        }

         /* 🎥 Correction des vidéos sur PC */
    .modal-content video {
        width: 80vw;  /* ✅ La vidéo est plus petite sur PC */
        height: 80vh;
        object-fit: contain; /* ✅ Garde les proportions et évite le plein écran */
    }
    
    .stories-container {
      justify-content: flex-start; /* Aligner à gauche si nécessaire */
      max-width: 80%; /* Ajuster la largeur max */
      margin: auto; /* Centrer les stories */
    }
  
    .stories-container img {
      width: 80px; /* Agrandir les avatars sur PC */
      height: 80px;
    }
  
    .story-box {
      transform: scale(1.2); /* Augmenter légèrement la taille */
    }
  }
  
  /* Pour les très grands écrans */
  @media (min-width: 1440px) {

    .modal-content {
        width: 80vw; /* Encore plus petit */
        height: 80vh;
      }

      .modal-content video {
        width: 60vw;
        height: 80vh;
    }

    .stories-container {
      max-width: 60%; /* Réduire la largeur */
    }
  
    .stories-container img {
      width: 100px; /* Encore plus grand sur un écran 4K */
      height: 100px;
    }
  }