/* ----- STRUCTURE GÉNÉRALE ----- */
.box-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}



/* ----- titre de la boxes ----- */
.box-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0; /* Espacement entre les éléments */
}

.box-title {
    font-size: 18px; /* Taille du texte */
    font-weight: bold; /* Optionnel : rendre le texte plus visible */
    text-align: center; /* Centrer le texte */
    max-width: 200px; /* Limite la largeur pour aligner avec le conteneur d'image */
    overflow: hidden; /* Empêche le débordement */
    white-space: nowrap; /* Pas de retour à la ligne */
    margin-bottom: 10px; /* Espacement avec l'image */
}





/* ----- ANIMATION DU GLISSEMENT ----- */
.drag-up {
    transform: translateY(-200%);
    opacity: 0;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

/* Assurez-vous que l'élément reste visible avant l'animation */
.result-overlay {
    background: rgba(255, 255, 255, 0); /* Complètement transparent */
    background-size: 400% 400%; /* Conserve les propriétés d'animation si nécessaire */
    animation: gradient-animation 8s ease infinite, bounce-up 1s ease-in-out infinite;
    transition: transform 0.5s ease, opacity 0.5s ease;
    border: 1px solid rgba(255, 255, 255, 0.1); /* Bordure subtile (facultatif) */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Ombre douce (facultatif) */
}


/* Animation du dégradé */
@keyframes gradient-animation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

/* Animation de prévisualisation */
@keyframes bounce-up {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}







/* ----- IMAGE GAGNANTE ----- */
.result {
    position: fixed;
    top: 50%; /* Centre verticalement */
    left: 50%; /* Centre horizontalement */
    transform: translate(-50%, -50%); /* Corrige l'alignement */
    z-index: 100; /* Toujours au-dessus */
    text-align: center;
    filter: none; /* Retirer tout flou de l'image gagnante */
}

.result img {
    max-width: 500px;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.result img:hover {
    transform: scale(1.1); /* Agrandissement au survol */
}

/* ----- IMAGE MINIATURE DE LA CAISSE ----- */
.thumbnail {
    max-width: 150px;
    height: auto;
    margin-bottom: 20px;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.thumbnail:hover {
    transform: scale(1.05); /* Zoom au survol */
}

.box-image-container {
    position: relative;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-image-container img {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    object-position: center;
}

/* ----- HOVER DE L'INFO DE LA CAISSE ----- */
.box-info-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75); /* Fond sombre */
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px;
    border-radius: 10px;
    z-index: 10;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4); /* Ombre subtile */
}

/* Toujours afficher l'overlay au hover */
.box-image-container:hover .box-info-hover {
    opacity: 1;
}

/* ----- DESCRIPTION LONGUE AVEC 'VOIR PLUS' ----- */
.box-info-hover p {
    margin: 5px 0;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.4;
    text-align: left; /* Alignement naturel sans espaces exagérés */
}

.box-info-hover a {
    color: #ffd700; /* Couleur dorée pour 'Voir plus' */
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
}

/* Gestion de la description longue par défaut */
.box-info-hover .long-description {
    display: none; /* Caché par défaut */
    overflow: auto;
    max-height: 100px; /* Hauteur limitée */
}

/* Afficher la description complète lorsqu'elle est étendue */
.box-info-hover.expanded .long-description {
    display: block; /* Afficher le texte complet */
    max-height: none; /* Pas de limitation de hauteur */
    overflow: visible; /* Texte totalement visible */
}

/* Gérer le conteneur étendu pour les descriptions longues */
.box-info-hover.expanded {
    overflow-y: auto; /* Défilement vertical activé */
    max-height: 200px; /* Limite de hauteur pour éviter de dépasser */
    -webkit-overflow-scrolling: touch; /* Défilement tactile fluide pour iOS */
    scroll-behavior: smooth; /* Défilement fluide */
}

/* Scrollbar stylisée (pour Chrome, Edge, etc.) */
.box-info-hover.expanded::-webkit-scrollbar {
    width: 8px; /* Largeur de la scrollbar */
}
.box-info-hover.expanded::-webkit-scrollbar-thumb {
    background-color: #ffd700; /* Couleur dorée pour le curseur */
    border-radius: 4px; /* Coins arrondis */
}
.box-info-hover.expanded::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1); /* Couleur de l'arrière-plan de la scrollbar */
}

/* ----- CONTAINER DES IMAGES ----- */
.media-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

/* ----- IMAGE INDIVIDUELLE DANS LE ROULEAU ----- */
.media-image-wrapper {
    position: relative;
    width: 80px !important;
    height: 97px !important;
    max-width: 80px !important;
    max-height: 97px !important;
    overflow: hidden;
    border-radius: 5px;
    margin: 0 1px !important; /* Ajoute un espacement horizontal entre les éléments */
    object-fit: cover !important; /* S'assure que l'image ou la vidéo remplit bien le conteneur */
}

.media-image {
    width: 80px !important;
    height: 97px !important;
    max-width: 80px !important;
    max-height: 97px !important;
    object-fit: cover !important; /* Recadre l'image ou la vidéo pour remplir le conteneur */
    border-radius: 5px;
    /*filter: blur(8px);*/
    transition: filter 0.3s ease;
}



.media-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px;
    font-size: 12px;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

/* Afficher les infos au survol */
.media-image-wrapper:hover .media-info {
    opacity: 1;
}

/* ----- ANIMATION DU ROULEAU D'IMAGES ----- */
.roll-animation {
    position: absolute;
    top: 50%; /* Centre verticalement */
    left: 50%; /* Centre horizontalement */
    transform: translate(-50%, -50%) translateZ(0); /* Combine le centrage et l'accélération GPU */
    width: 500px; /* Largeur fixe (modifie si nécessaire) */
    overflow: hidden;
    height: 120px; /* Hauteur du conteneur */
    z-index: 10; /* Superposition */
    will-change: transform; /* Optimise les performances */
}

.roll-media, .roll-image {
    flex: 0 0 80px !important; /* Empêche l'ajustement de taille, c'est lui qui change la taille des images */
    width: 80px !important;
    height: 97px !important;
    max-width: 80px !important;
    max-height: 97px !important;
    object-fit: cover !important;
    margin: 0 1px !important; /* Ajoute un espacement horizontal entre les éléments */
    display: block !important; /* Pour éviter tout comportement inline */
    border: 1px solid rgba(255, 255, 255, 0.879); /* Bordure temporaire pour vérification */
    will-change: transform, opacity; /* Optimise l'animation et le rendu */
    backface-visibility: hidden; /* Améliore les performances GPU */
    transform: translateZ(0); /* Force le rendu GPU */
}


.uniform-roll-item {
    width: 80px !important; /* Largeur uniforme */
    height: 97px !important; /* Hauteur uniforme */
    max-width: 80px !important;
    max-height: 97px !important;
    object-fit: cover !important; /* Pour que le contenu s'adapte à la taille */
    margin: 0 1px !important; /* Ajoute un espacement horizontal entre les éléments */
  }

  
  .roll-track {
    display: flex;
    animation: roll 8.1s cubic-bezier(0.22, 1, 0.36, 1) forwards;
}


/* Animation principale pour le défilement */
@keyframes roll {
    0% { transform: translateX(0); }
    100% { transform: translateX(-2395px); } /* Position finale */
}


.fixed-cursor {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 95px;
    height: 82%;
    background: url('/assets/cursor-image.png') no-repeat center;
    background-size: contain;
    pointer-events: none;
    z-index: 20;
}

/* ----- MODAL ----- */
.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 80%;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    color: #fff;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
}

.overlay {
    background: rgba(255, 255, 255, 0.75);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}

/* ----- IMAGE GAGNANTE RESPONSIVE ET ICÔNE DE TÉLÉCHARGEMENT ----- */
.result-image {
    max-width: 100%; /* Responsive */
    height: auto;
    max-height: 80vh; /* Limite la hauteur pour éviter de dépasser l'écran */
}

.download-icon {
    position: absolute;
    bottom: 20px;
    right: 10px;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 10px;
    border-radius: 50%;
    transition: transform 0.2s ease;
}

.download-icon:hover {
    transform: scale(1.2); /* Zoom au survol */
}

.download-icon svg {
    fill: white;
    width: 24px;
    height: 24px;
}

/* ----- TOAST MESSAGE ----- */
.toast-message {
    bottom: 50px; /* Évite de cacher l'icône de téléchargement */
}



/* Appliquer un arrière-plan personnalisé avec un dégradé élégant à toute la page */
body {
    background: linear-gradient(135deg, #ff0051 0%, #f4750c 100%) !important; /* Un dégradé léger et doux */
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center; /* Centre verticalement */
    justify-content: center; /* Centre horizontalement */
    font-family: 'Roboto', sans-serif; /* Utilisation d'une police élégante */
    transition: background 0.5s ease-in-out; /* Transition douce lors du changement de couleur */
}

/* Effet de transition fluide pour l'apparition de la box */
.box-detail {
    background-color: hsla(51, 88%, 60%, 0.084); /* Arrière-plan blanc propre pour la box */
    box-shadow: 0 8px 16px rgb(169, 42, 233); /* Ombre douce pour un effet flottant */
    border-radius: 15px; /* Coins arrondis élégants */
    padding: 20px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn 1.2s ease-out; /* Animation d'apparition douce */
    transition: box-shadow 0.3s ease; /* Ombre subtile lors du survol */
}

/* Ajouter une légère ombre lors du survol */
.box-detail:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3); /* Accentuation de l'ombre au survol */
}

/* Animation de fondu pour un effet plus subtil à l'entrée */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(30px); /* Départ léger vers le bas */
    }
    to {
        opacity: 1;
        transform: translateY(0); /* Arrivée à sa position normale */
    }
}

/* Ajustement du style des images dans le conteneur pour plus de cohérence */
.box-image-container img {
    border-radius: 10px; /* Ajouter des coins arrondis à l'image */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Ombre douce */
}

.box-image-container img:hover {
    transform: scale(1.05); /* Légère augmentation de la taille au survol */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3); /* Ombre plus forte au survol */
}

/* Effet plus élégant sur les images miniatures dans la galerie */
.media-image-wrapper:hover .media-image {
    filter: blur(3px); /* Réduire légèrement le flou au survol */
    transform: scale(1.02); /* Zoom subtil au survol */
}