/* src/styles/HomePage.css */


html, body {
  width: 100%;
  overflow-x: hidden; /* Empêche les débordements horizontaux */
  
}


.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black; /* Set the background to black */
  z-index: -1;


  /* Désactiver tout comportement de zoom sur l'image */
  will-change: transform;
  transform: translateZ(0); /* Astuce pour s'assurer que l'image ne se distord pas pendant le scroll */
}


* {
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  color: #fff;
  overflow-x: hidden;
  background: linear-gradient(135deg, #1a1a2e, #16213e);
}

/* Styles généraux pour le menu déroulant */
.dropdown-menu {
  width: 250px; /* Largeur par défaut pour le menu */
  padding: 10px; /* Espace autour des éléments */
  background-color: #1a1a1a; /* Fond sombre pour le menu */
  border-radius: 8px; /* Coins arrondis */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4); /* Ombre pour donner de la profondeur */
  position: absolute; /* Permet d'assurer un positionnement correct */
  top: 60px; /* Ajustez en fonction de la hauteur de la barre supérieure */
  right: 10px; /* Place le menu en haut à droite */
  z-index: 1001; /* Pour s'assurer qu'il est au-dessus des autres éléments */
}

/* Style des boutons du menu déroulant */
.menu-item {
  width: 100%; /* Les boutons prennent toute la largeur */
  padding: 8px 12px; /* Espacement interne pour le bouton */
  font-size: 14px; /* Taille de texte réduite pour un look compact */
  margin: 5px 0; /* Espacement entre les boutons */
  color: #ff007f; /* Couleur du texte */
  text-align: left; /* Alignement du texte à gauche */
  background-color: transparent; /* Fond transparent par défaut */
  border: 1px solid #ff007f; /* Bordure colorée pour les boutons */
  border-radius: 5px; /* Coins arrondis */
  cursor: pointer; /* Indicateur de clic */
  transition: background-color 0.3s; /* Animation de fond */
}

.menu-item:hover {
  background-color: #ff007f; /* Fond coloré au survol */
  color: #fff; /* Texte blanc pour le contraste */
}

/* Style spécifique pour le solde des clés dans le menu */
.user-balance {
  font-size: 16px; /* Taille de texte plus grande pour le solde */
  color: yellow; /* Couleur jaune pour le contraste */
  margin-bottom: 10px; /* Espacement sous le solde des clés */
  display: block; /* Pour occuper toute la largeur */
  text-align: left; /* Alignement du texte à gauche */
}

.header {
  position: fixed; /* Garde le header en haut de la page */
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: #000; /* Couleur de fond pour rendre le header visible */
  z-index: 1000; /* Pour qu'il reste au-dessus des autres éléments */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Permet aux éléments de s'empiler si nécessaire sur petit écran */
}

.header-left {
  display: flex;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 15px; /* Espacement entre les éléments */
}

.search-bar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto; /* Centrage horizontal global */
  background: rgba(255, 255, 255, 0.1); /* Fond semi-transparent */
  border-radius: 8px; /* Arrondi les coins */
  padding: 5px 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25); /* Ombre subtile pour du relief */
  backdrop-filter: blur(10px); /* Ajoute un effet de flou */
  max-width: 400px;
  position: relative; /* Assure qu'elle reste dans le flux normal */
  transition: transform 0.3s, box-shadow 0.3s; /* Animation fluide pour hover */
}

.search-bar-container:hover {
  transform: translateY(-5px); /* Légère élévation au hover */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* Accentuation de l'ombre */
}


.search-bar-input {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
}

.search-bar-input::placeholder {
  color: rgba(255, 255, 255, 0.7); /* Couleur blanche semi-transparente */
  font-style: italic; /* Ajoute une touche élégante au placeholder */
}


.content {
  position: relative;
  z-index: 1;
  padding: 20px;
  margin-top: 60px; /* Ajustez cette valeur en fonction de la hauteur du header */
}

h1 {
  text-align: center;
  margin: 60px 0 40px;
  font-size: 3em;
  color: #e94560;
  letter-spacing: 3px;
  text-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}



/* Conteneur parent */
.direct-openings-section,
.user-openings-section {
  display: flex; /* Active Flexbox */
  flex-direction: column; /* Empile le contenu verticalement */
  justify-content: flex-start; /* Alignement en haut pour inclure le titre */
  align-items: center; /* Centre verticalement */
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Ombre légère */
  max-width: 600px; /* Largeur maximale fixe */
  width: 80%; /* Largeur relative à l'écran */
  margin: 0 auto; /* Centre horizontalement */
  overflow-y: auto; /* Défilement vertical activé si le contenu dépasse */
  overflow-x: hidden; /* Empêche le défilement horizontal */
  max-height: 160px; /* Hauteur limitée au titre + 3 ouvertures */
  transition: max-height 0.4s ease, box-shadow 0.4s ease; /* Animation fluide */
  scrollbar-width: none; /* Cacher la barre de défilement sur Firefox */
}

/* Masquer les barres de défilement sur Chrome, Edge et Safari */
.direct-openings-section::-webkit-scrollbar {
  display: none; /* Supprime la barre de défilement */
}

.direct-openings-section:hover {
  max-height: 500px; /* Hauteur augmentée au survol */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); /* Ombre légèrement amplifiée */
}

/* Conteneur de la liste */
.openings-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: #ddd;
  max-height: 100%; /* La hauteur s'ajustera avec la section */
  text-align: center; /* Centre le texte */
}


/* Masquer les barres de défilement sur Chrome, Edge et Safari */
.user-openings-section::-webkit-scrollbar {
  display: none; /* Supprime la barre de défilement */
}

.user-openings-section:hover {
  max-height: 500px; /* Hauteur augmentée au survol */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); /* Ombre légèrement amplifiée */
}

/* Pour améliorer la barre de défilement (sur Chrome, Edge, et Safari) */
.direct-openings-section::-webkit-scrollbar,
.user-openings-section::-webkit-scrollbar {
  width: 5px;
}

.direct-openings-section::-webkit-scrollbar-thumb,
.user-openings-section::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5); /* Couleur de la barre */
  border-radius: 5px;
}

.direct-openings-section::-webkit-scrollbar-track,
.user-openings-section::-webkit-scrollbar-track {
  background: transparent; /* Fond transparent */
}



.category-section {
  padding: 0;
  border-radius: 15px;
  backdrop-filter: blur(10px);
  box-shadow: none;
  transition: none; /* Test temporaire */
  width: 100%; /* S'assurer que chaque section prend la largeur de la page */
  max-width: 100%;
  overflow: hidden; /* Pour éviter les débordements */
}


.categories {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0; /* Ajouter un padding horizontal pour les petits écrans */
  width: 100%; /* Prendre toute la largeur de l’écran */
  max-width: 100vw; /* Limite la largeur totale à celle de la fenêtre */
  overflow-y: auto; /* Scrolle si nécessaire */
  box-sizing: border-box; /* Inclut le padding dans la largeur totale */
}

/* Titre H1 */
.category-title {
  margin-bottom: 20px; /* Ajuste l'espace sous le titre */
  color: #f0a500; /* Couleur or */
  font-size: 1.8em;
  text-transform: uppercase;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  text-align: center; /* Centre le texte */
}


.category-section:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.7);
}

/* Assure que le conteneur commence avec la première boîte visible */
.boxes {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth; /* Transition douce lors du défilement */
  gap: 10px;
  flex-wrap: nowrap; /* Assure le défilement horizontal sans enroulement */
  padding: 0;
  scroll-padding: 0px;
  margin: 0;
  align-items: flex-start; /* Assure un alignement non centré */
  justify-content: flex-start; /* Aligne toutes les boîtes à gauche */
}

/* Masquer la scrollbar sur les navigateurs compatibles */
.boxes::-webkit-scrollbar {
  display: none;
}


.box {
  min-width: 180px; /* Ajuste cette valeur pour contrôler le nombre de caisses visibles */
  flex: 0 0 auto;
  scroll-snap-align: start;
  background: #a0000000;
  border-radius: 12px;
  padding: 10px; /* Ajuste le padding ici si nécessaire */
  text-align: center;
  color: #e0e0e0; /* Prix boxes TTC color */
  transition: transform 0.5s, box-shadow 0.5s, background 0.5s;
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  cursor: pointer;
  box-sizing: border-box; /* Ajoute cette ligne */
  flex-shrink: 0; /* Empêche la boîte de rétrécir */
}

.box:first-child {
  scroll-snap-align: start; /* Assure que la première boîte est toujours ancrée à gauche */
  margin-left: 0; /* Supprime toute marge à gauche pour un alignement à gauche */
}

.box:hover {
  transform: scale(1.08);
  background: linear-gradient(145deg, #3b3c5400, #1e1f3a00);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
  border-color: #f0a500;
}

.box-thumbnail {
  width: 120px; /* Diminuez la largeur selon la taille souhaitée */
  height: 100px; /* Diminuez la hauteur selon la taille souhaitée */
  border-radius: 10px;
  margin: auto; /* Centre la miniature horizontalement */
  display: block; /* Centre l’image dans son conteneur */
  transition: transform 0.4s;
}

.box-thumbnail:hover {
  transform: scale(1.1) rotate(-2deg);
}


.box h3 {
  margin: 0;
  font-size: 1.4em;
  color: #ffffff; /* Titre boxes à linterieur de la boxes */  
  transition: color 0.3s;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.box:hover h3 {
  color: #e94560;
}

.box p {
  margin: 8px 0 0;
  font-size: 1em;
}

.indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.indicator svg {
  font-size: 1.5rem; /* Ajustez la taille selon vos préférences */
  color: green; /* Couleur de l'icône */
}

.indicator span {
  font-size: 0.8rem;
  margin-left: 5px; /* Petit espacement entre l'icône et le texte */
  color: green; /* Même couleur que l'icône */
  font-weight: bold;
}


.box-type-indicator {
  margin-top: 8px;
  display: flex;
  gap: 8px;
}

.box-type-indicator span {
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 8px;
  padding: 4px 8px;
}

.photo-only {
  background-color: #007bff;
  color: white;
}

.video-type {
  background-color: #ffa500;
  color: white;
}

button {
  background-color: #e94560;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.4s, transform 0.3s;
  margin-top: 15px;
}

button:hover {
  background-color: #f0a500;
  transform: translateY(-3px);
}


.openings-section::-webkit-scrollbar {
  display: none; /* Cache la scrollbar pour les navigateurs WebKit */
}

.openings-section {
  scrollbar-width: none; /* Cache la scrollbar pour Firefox */
}

.openings-section {
  padding: 10px; /* Réduction du padding */
  border-radius: 10px; /* Coins légèrement arrondis */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Ombre légère */
  transition: transform 0.4s, box-shadow 0.4s; /* Animation au survol */
  margin-bottom: 10px; /* Réduction de l'espacement */
  max-width: 80%; /* Limite la largeur maximale */
  margin: 0 auto; /* Centre la section horizontalement */
  overflow: hidden; /* Cache les éléments excédentaires */
  transition: max-height 0.4s ease, transform 0.2s ease, box-shadow 0.4s ease; /* Animation pour dérouler la liste */
  max-height: 160px; /* Hauteur correspondant à 3 éléments visibles */
}

.openings-section:hover {
  max-height: 500px; /* Permet de dérouler les éléments */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.5); /* Accentue l'ombre */
  overflow-y: auto; /* Ajoute un défilement si la liste dépasse */
}


.openings-section h2 {
  color: #f0a500; /* Conserve la couleur */
  font-size: 1.2em; /* Réduit la taille de la police */
  text-transform: uppercase;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); /* Réduction de l'ombre */
  margin-bottom: 10px; /* Réduction de l'espacement */
  text-align: center; /* Aligne le texte au centre */
}



/* Style pour la liste des ouvertures */


.openings-list-item {
  display: flex; /* Flexbox pour aligner l'avatar et le texte */
  align-items: center; /* Centre verticalement */
  margin-bottom: 8px; /* Espacement réduit */
}

.openings-list-item img {
  width: 30px; /* Taille plus petite pour les avatars */
  height: 30px;
  border-radius: 50%; /* Rendu circulaire */
  margin-right: 10px; /* Espacement réduit */
  object-fit: cover; /* Garde les proportions */
}

.openings-list-item strong {
  color: #f0a500; /* Couleur du titre de la caisse */
  font-size: 0.9em; /* Réduction de la taille */
}

.openings-list-item:hover {
  transform: scale(1.02); /* Légère mise en avant au survol */
  transition: transform 0.2s ease;
  color: #fff; /* Accentuation de la couleur */
}

/* Media Queries for responsiveness */

/* Écrans jusqu'à 1024px */
@media (max-width: 1024px) {

  .openings-section {
    max-height: 160px;
    overflow-y: hidden;
    padding: 8px;
  }

  .openings-section:active {
    max-height: 500px;
    overflow-y: auto;
  }

  h1 {
    font-size: 2.5em;
  }

  .header {
    flex-wrap: wrap;
    padding: 10px;
  }

  .search-bar-container {
    margin: 10px 0;
    width: 100%;
  }
  
  .category-section h2 {
    font-size: 1.6em;
    margin-bottom: 15px;
  }
}

/* Petits écrans jusqu'à 768px */
@media (max-width: 768px) {

  .openings-section {
    max-height: 160px;
    overflow-y: hidden;
    padding: 8px;
  }

  .openings-section:active {
    max-height: 500px;
    overflow-y: auto;
  }

  .openings-list-item img {
    width: 25px;
    height: 25px; /* Avatars plus petits */
  }

  .openings-section h2 {
    font-size: 1em; /* Réduction de la taille de police */
  }

  .box {
    min-width: 150px; /* Ajusté pour écrans jusqu'à 768px */
    flex: 0 0 auto;
    scroll-snap-align: start;
  }

  .header {
    flex-direction: column;
    align-items: center;
  }

  .header-left,
  .header-right {
    justify-content: center;
    margin-bottom: 10px;
  }

  .search-bar-container {
    width: 90%; /* S'adapte à la largeur de l'écran */
    max-width: none; /* Supprime les restrictions de largeur */
  }

  h1 {
    font-size: 2.2em;
  }
  .category-section h2 {
    font-size: 1.4em;
    margin-bottom: 12px;
  }
  .boxes {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding: 0;
    padding-bottom: 10px;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap; /* Évite l'enroulement des éléments */
  }
  
  .box-thumbnail {
    width: 100px;
    height: 80px;
  }
}

/* Écrans très petits, jusqu'à 480px */
@media (max-width: 480px) {

  .openings-section {
    padding: 10px;
  }

  .openings-list-item img {
    width: 30px;
    height: 30px;
  }

  .openings-section h2 {
    font-size: 1.2em;
  }


  .box {
    min-width: 90px; /* Largeur fixe pour les écrans très petits */
    flex: 0 0 auto;
    scroll-snap-align: start;
    margin: 0; /* Supprime toute marge potentiellement ajoutée */
  }

  .search-bar-input {
    font-size: 14px; /* Assurez-vous que le texte est lisible */
    padding: 8px; /* Ajuste l'espacement interne */
    min-width: 220px; /* Assure une largeur minimale pour le texte */
  }

  .search-bar-container {
    width: 95%; /* Prend toute la largeur disponible */
    max-width: 100%; /* Supprime la limite de largeur */
    padding: 6px 10px; /* Ajuste les marges internes */
  }

  h1 {
    font-size: 1.4em;
    margin: 10px 0; /* Réduction des marges */
  }
  .category-section h2 {
    font-size: 1em;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 8px;
  }
  .boxes {
    display: flex;
    overflow-x: auto;
    gap: 5px;
    padding: 0;
    padding-bottom: 10px;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap; /* Assure un défilement horizontal */
  }
  
  .box h3 {
    font-size: 1em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .box-thumbnail {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
}

/* Écrans très petits jusqu'à 400px */
@media (max-width: 400px) {
  
  .box {
    min-width: 80px;
    flex: 0 0 auto;
    scroll-snap-align: start;
    margin: 0; /* Supprime toute marge potentiellement ajoutée */
  }

  .search-bar-input {
    font-size: 13px; /* Réduit légèrement la taille du texte */
    padding: 10px 12px; /* Ajoute encore un peu d'espace */
    min-width: 240px; /* Largeur minimale pour que le texte s'affiche */
  }

  .search-bar-container {
    width: 100%; /* S'étend sur toute la largeur */
    max-width: 100%; /* Assurez-vous qu’il n’y a pas de coupure */
    padding: 8px 10px; /* Ajustez le padding pour optimiser l’espace */
  }

  h1 {
    font-size: 1.4em;
    margin: 15px 0;
  }
  .category-section h2 {
    font-size: 1em;
    margin: 5px 0;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .boxes {
    display: flex;
    overflow-x: auto;
    gap: 5px;
    padding: 0;
    padding-bottom: 10px;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap; /* Assure un défilement horizontal pour éviter les débordements */
  }
  
  .box h3 {
    font-size: 0.8em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .box p {
    font-size: 0.8em;
  }
  .box-thumbnail {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
}

/* Ajustement pour les écrans entre 400px et 480px */
@media (min-width: 400px) and (max-width: 480px) {

  .box {
    min-width: 80px;
    flex: 0 0 auto;
    scroll-snap-align: start;
    margin: 0; /* Supprime toute marge potentiellement ajoutée */
  }


  h1 {
    font-size: 1.4em;
    margin: 20px 0;
  }
  .category-section h2 {
    font-size: 1.1em;
    margin-bottom: 8px;
    text-align: center;
  }
  .boxes {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 8px;
    padding: 0;
    padding-bottom: 10px;
    scroll-snap-type: x mandatory;
  }
  
  .box h3 {
    font-size: 0.9em;
  }
  .box-thumbnail {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
}

/* Ajustement pour les écrans entre 481px et 768px */
@media (min-width: 481px) and (max-width: 768px) {

  .box {
    min-width: 100px;
    flex: 0 0 auto;
    scroll-snap-align: start;
  } 

  h1 {
    font-size: 2em;
  }
  .category-section h2 {
    font-size: 1.3em;
    text-align: left;
    margin: 0 10px;
  }
  .boxes {
    display: flex;
    overflow-x: auto;
    gap: 10px;
    padding-bottom: 10px;
    scroll-snap-type: x mandatory;
  }
  
  .box-thumbnail {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
}


/* Effet de Hover pour .box */
.box:hover {
  transform: scale(1.08);
  background: linear-gradient(145deg, #3b3c5400, #1e1f3a00);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
  border-color: #f0a500;
}


.btn {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.btn-login {
  background-color: #4caf50; /* Vert pour connexion */
  color: white;
}

.btn-signup {
  background-color: #008cba; /* Bleu pour inscription */
  color: white;
}

.btn-logout {
  background-color: #f44336; /* Rouge pour déconnexion */
  color: white;
}

.btn:hover {
  opacity: 0.8;
}

.auth-links {
  display: flex;
  gap: 10px;
  justify-content: center; /* Centre horizontalement */
  align-items: center; /* Centre verticalement */
  flex-wrap: wrap; /* Permet aux boutons de s'empiler sur petits écrans */
}

@media (max-width: 480px) {
  .auth-links {
    flex-direction: column; /* Empile les boutons sur petits écrans */
    gap: 15px; /* Ajoute un peu plus d'espace entre eux */
  }

  button {
    width: 100%; /* Les boutons prennent toute la largeur disponible */
    max-width: 300px; /* Limite la largeur maximale des boutons */
  }
}


@media (max-width: 480px) {
  .header {
    justify-content: center; /* Centre les éléments sur petits écrans */
  }
}


.carousel-container {
  width: 100%; /* Le carrousel occupe toute la largeur */
  max-width: 400px; /* Limite la largeur maximale */
  margin: 0 auto; /* Centrer le carrousel */
  overflow: hidden; /* Évite que des éléments débordent */
}

.slick-slide {
  display: flex;
  justify-content: center; /* Centrer les images horizontalement */
  align-items: center; /* Centrer verticalement */
}

.slick-slide img {
  max-width: 100%; /* Adapte la largeur des images au conteneur */
  height: auto; /* Maintient les proportions d'origine */
  object-fit: contain; /* Les images s'ajustent tout en respectant leur ratio */
  max-height: 300px; /* Limite la hauteur maximale des images (facultatif) */
}

@media (max-width: 768px) {
  .slick-slide img {
    max-height: 200px; /* Réduit la hauteur sur les petits écrans */
  }
}