/* Centrer le formulaire */
form {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff; /* Blanc pour ModelSignupPage */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
  }
  
  /* Styles pour les champs de saisie */
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 12px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 16px;
  }
  
  /* Style pour le bouton */
  button[type="submit"] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  button[type="submit"]:hover {
    background-color: #45a049;
  }
  
  /* Styles pour le texte d'erreur ou d'information */
  .message {
    margin-top: 10px;
    color: red;
    font-size: 14px;
    text-align: center;
  }
  
  /* Ajouter une transition pour les éléments interactifs */
  input[type="text"],
  input[type="email"],
  input[type="password"],
  button[type="submit"] {
    transition: all 0.3s ease;
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="password"]:focus {
    border-color: #4CAF50;
    outline: none;
  }

  input {
    padding-right: 2.5rem; /* Assure un espace suffisant pour l'icône à droite */
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  input, .input-group {
    width: 100%; /* Assure que les champs occupent tout l'espace disponible */
    margin-bottom: 1rem;
  }
  
  .input-group {
    position: relative;
  }
  
  .input-group input {
    padding-right: 2.5rem; /* Ajoute de l'espace à droite pour l'icône */
  }
  
  .input-group .chakra-icon {
    position: absolute;
    right: 0.5rem; /* Ajuste l'espace entre l'icône et le bord droit */
    top: 50%;
    transform: translateY(-50%); /* Centre l'icône verticalement */
  }
  